import packageJson from '../../package.json';

export const environment = {
    production: true,
    version: packageJson.version,
    configPath: 'config.json',
    federation: {
        manifest: '/assets/federation.manifest.dev.json',
    },
    imports: [],
};
